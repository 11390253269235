const {
  createContext,
  useContext,
  useMemo
} = React;
import { retailerApi } from '@common/retailer-api';
import { useStoredMessagesContext } from './stored-messages-provider';
const MessagesContext = createContext();
const isMessageRead = (message, storedMessages, now) => {
  const messageData = storedMessages.get(message.id);
  if (!messageData || isNaN(messageData.timestamp)) {
    return false;
  }
  const hoursDifference = dateFns.differenceInHours(now, messageData.timestamp);
  return hoursDifference >= 24;
};
const countUnreadMessages = messagesWithIsRead => messagesWithIsRead.filter(({
  isRead
}) => !isRead).length;
const compareMessages = (a, b) => dateFns.compareDesc(dateFns.parseISO(a.message.savedDate), dateFns.parseISO(b.message.savedDate));
export const MessagesProvider = ({
  children
}) => {
  const {
    data: messages = [],
    isError,
    isFetching
  } = retailerApi.useGetMessagesQuery();
  const {
    readMessages
  } = useStoredMessagesContext();
  const now = new Date();
  const messagesWithIsRead = messages.map(message => ({
    message,
    isRead: isMessageRead(message, readMessages, now)
  }));
  const recentMessages = messagesWithIsRead.filter(({
    message
  }) => dateFns.differenceInDays(now, dateFns.parseISO(message.savedDate)) <= 14).sort(compareMessages);
  const recentMessageIds = recentMessages.map(({
    message
  }) => message.id);
  const oldMessages = messagesWithIsRead.filter(({
    message
  }) => !recentMessageIds.includes(message.id)).sort(compareMessages).slice(0, 5);
  const unreadMessagesCount = countUnreadMessages(oldMessages) + countUnreadMessages(recentMessages);
  const value = useMemo(() => ({
    recentMessages,
    oldMessages,
    unreadMessagesCount,
    hasError: isError,
    isLoading: isFetching
  }), [recentMessages, oldMessages, unreadMessagesCount, isError, isFetching]);
  return <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>;
};
export const useMessagesContext = () => useContext(MessagesContext);