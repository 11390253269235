import { useStoredMessagesContext } from '../../stored-messages-provider';
import { useMessagesContext } from '../../messages-provider';
import { getClassNamesFromArray } from '@common/utils';
import { ErrorPaper } from '@common/components/error-paper';
import Messages from './messages';
import './messages.less';
const {
  useState
} = React;
const {
  ReactButton: Button,
  ReactIcon: Icon
} = svs.ui;
const MessagesContainer = () => {
  const {
    showReadInfo,
    markMessageAsRead
  } = useStoredMessagesContext();
  const {
    recentMessages,
    oldMessages,
    isLoading,
    hasError
  } = useMessagesContext();
  const [visibleRecentMessages, setVisibleRecentMessages] = useState(10);
  const [expanded, setExpanded] = useState(null);
  const handleAccordionChange = (messageId, isExpanded) => {
    setExpanded(isExpanded ? messageId : null);
    if (isExpanded) {
      markMessageAsRead(messageId);
    }
  };
  const loadMoreRecentMessages = () => {
    setVisibleRecentMessages(recentMessages.length);
  };
  return <div className={getClassNamesFromArray(['container-width', 'messages-error', isLoading && 'is-loading'])}>
      <div className="info-div u-flex">
        <Icon icon="information" size="300" />
        <span className="margin-left text-span">
          Detta är samma meddelanden som visas i Lottapplikationen och i Spelterminalen.
          {' '}
          {showReadInfo && 'Meddelandet markeras som läst 24 timmar efter att du klickat på det.'}
        </span>
      </div>
      {recentMessages.length !== 0 && <React.Fragment>
          <Messages expanded={expanded} messages={recentMessages.slice(0, visibleRecentMessages)} onAccordionChange={handleAccordionChange} title="Senaste 14 dagar" />
          {recentMessages.length > visibleRecentMessages && <div className="margin-top-2 u-flex u-flex-align-self-center u-flex-justify-content-center">
              <Button onClick={loadMoreRecentMessages} transparent>
                ladda fler
              </Button>
            </div>}
        </React.Fragment>}
      {oldMessages.length !== 0 && <div className="padding-top-3">
          <Messages expanded={expanded} messages={oldMessages} onAccordionChange={handleAccordionChange} title="Äldre meddelanden" />
        </div>}
      {hasError && <ErrorPaper />}
    </div>;
};
export default MessagesContainer;